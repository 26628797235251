<template>
  <hs-main class="confirm">
    <template v-if="loadingInProgress">
      <div class="confirm__loader-cont">
        <i class="confirm__loader"></i>
      </div>
    </template>
    <template v-else>
      <hs-heading
        v-show="productLineName"
        :title="`Select An Option for ${equipmentName}`"
        class="service-request__title"
      />
      <div class="service-request__row">
        <div class="service-request__col service-request__col--icon">
          <device-icon
            v-show="productLineName"
            :icon="productLineName"
            class="service-request__device-icon"
          />
        </div>
        <div class="service-request__col">
          <!-- buyout amount is selected from multiple adjudication options BEGIN -->
          <template v-if="type === 'multiple'">
            <b class="service-request__title">
              Please review Buyout option below and click on "Proceed with Buyout" if you agree
            </b>
            <p class="service-request__option-description">
              We will pay you {{ buyoutAmount }} based on the fair market value of your device at
              the time the Service Call was made, up to the benefit limit amount minus the Service
              Call fee. Once you accept this buyout option, payment processing and delivery will
              take up to 30 days.
              <br />
              <strong>
                Note: Your device will be automatically removed from your device list.
                <br class="confirm__br" />
                Go to Service History to check on your Service Call progress.
              </strong>
            </p>
          </template>
          <!-- buyout amount is selected from multiple adjudication options END -->
          <!-- buyout amount is selected from a single adjudication option BEGIN -->
          <template v-else>
            <div class="service-request__title">
              To resolve your issue, we offer a Buyout option. <br class="confirm__br" />
              Please proceed if you agree
            </div>
            <div class="service-request__option">
              <b class="service-request__title">Buyout</b>
              <p class="service-request__option-description">
                We will pay you {{ buyoutAmount }} based on the fair market value of your device at
                the time the Service Call was made, up to the benefit limit amount minus the Service
                Call fee. Once you accept this buyout option, payment processing and delivery will
                take up to 30 days.
                <br />
                <strong>
                  Note: Your device will be automatically removed from your device list.
                  <br class="confirm__br" />
                  Go to Service History to check on your Service Call progress.
                </strong>
              </p>
            </div>
          </template>
        </div>
      </div>
      <!-- buyout amount is selected from a single adjudication option END -->
      <step-navigation-buttons
        :has-back-button="showBackButton"
        :mobile-button-width="268"
        :confirm-button-text="proceedBuyoutText"
        :back-button-text="declineBuyoutText"
        :disabled="isSubmitted"
        :disabled-back="isSubmitted"
        @back="declineBuyout"
        @confirm="proceedBuyout"
      />
    </template>
  </hs-main>
</template>

<script>
import '@/assets/styles/service-request.scss';
import {mapGetters, mapState, mapActions} from 'vuex';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import DeviceIcon from '@/components/shared/DeviceIcon';

export default {
  components: {
    StepNavigationButtons,
    DeviceIcon,
  },
  data() {
    return {
      seekingJob: null,
      loadingInProgress: true,
      isSubmitted: false,
      calculatedBuyout: null,
    };
  },
  methods: {
    ...mapActions('requestService', ['ACCEPT_BUYOUT', 'REJECT_BUYOUT']),
    async declineBuyout() {
      this.isSubmitted = true;
      await this.REJECT_BUYOUT(this.masterId);
      const {query} = this.$route;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    async proceedBuyout() {
      this.isSubmitted = true;
      await this.ACCEPT_BUYOUT(this.masterId);
      const {query} = this.$route;
      const id = this.deviceId;
      this.$router.push({
        name: 'StartSpecificClaim',
        params: {
          id,
          type: 'reimbursement',
        },
        query: {
          ...query,
          step: 'explanation',
        },
      });
    },
  },
  computed: {
    ...mapGetters('jobInfo', ['getByDeviceId']),
    ...mapState('deviceInformation', ['productLineName', 'equipmentName']),
    isBuyoutClaim() {
      // Based on HS- status name we assume that a claim is buyout.
      // Make sure that statement is consistent.
      return this.seekingJob?.serviceJobID?.toLowerCase().startsWith('hscm');
    },
    deviceId() {
      return this.$route.params.id;
    },
    buyoutAmount() {
      let amount = this.calculatedBuyout;
      if (!amount) {
        amount = this.seekingJob?.claimDetails?.buyoutAmount;
      }
      if (amount) {
        return `$${parseInt(amount, 10)}`;
      }
      return 'a buyout amount';
    },
    masterId() {
      return this.seekingJob?.claimsMasterId || null;
    },
    showBackButton() {
      return !!this.masterId;
    },
    type() {
      return this.$route.params.type;
    },
    proceedBuyoutText() {
      // Text depending on buyout amount is selected from multiple adjudication options
      return this.type === 'multiple' ? 'Proceed with Buyout' : 'Proceed with Buyout';
    },
    declineBuyoutText() {
      // Text depending on buyout amount is selected from multiple adjudication options
      return this.type === 'multiple' ? 'Decline Buyout' : 'Decline Buyout';
    },
  },
  watch: {
    deviceId: {
      async handler(id) {
        const {customerRef} = this.$route.query;

        const resp = await this.$store.dispatch('user/DECRYPT_PARAMS', {
          customerRef,
        });

        await this.$store.dispatch('deviceInformation/GET_DEVICE_INFO', {
          id,
          customerRef: resp?.customerRef,
        });

        await this.$store.dispatch('jobInfo/GET_CLAIMS_LIST', {
          customerRef: resp?.customerRef,
        });

        this.seekingJob = this.getByDeviceId(this.deviceId);

        if (this.isBuyoutClaim) {
          const buyoutValue = await this.$store.dispatch('jobInfo/GET_BUYOUT_AMOUNT', {
            serviceJobId: this.seekingJob.serviceJobID,
          });
          this.calculatedBuyout = buyoutValue;
          this.loadingInProgress = false;
        } else {
          this.loadingInProgress = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.confirm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0;

  &__br {
    display: none;
  }

  @include mf($gridSMBreakpoint) {
    &__br {
      display: block;
    }
  }

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }

  &__loader {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background-image: url('~@/assets/images/hs-loader.gif');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
    &-cont {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
